const options = {
    item: 'js-geo-modal',
    close: 'js-close',
    link: 'js-link',
    session: 'iqeq_geo_popup',
    dialog: 'js-geo-modal__dialog',
}

const geolocation = {
    init() {
        const item = document.getElementsByClassName(options.item);
        const modals = [...item];

        modals.forEach((modal) => {
            const dialog = modal.getElementsByClassName(options.dialog);
            const linkBtn = modal.getElementsByClassName(options.link);
            const linkBtns = [...linkBtn];
            const closeBtn = modal.getElementsByClassName(options.close);
            const closeBtns = [...closeBtn];

            dialog[0].setAttribute('open', true);

            if (!sessionStorage.getItem(options.session)) {
                modal.classList.add('active');
            }

            linkBtns.forEach((link) => {
                link.addEventListener('click', (e) => {
                    e.preventDefault();

                    const target = e.target;

                    if (!sessionStorage.getItem(options.session)) {
                        sessionStorage.setItem(options.session, 'true');
                    }

                    window.location.href = target.href;
                });
            });

            closeBtns.forEach((close) => {
                close.addEventListener('click', (e) => {
                    const target = e.target;

                    if (!sessionStorage.getItem(options.session)) {
                        sessionStorage.setItem(options.session, 'true');
                    }

                    target.closest(`.${options.item}`).classList.remove('active');
                });
            });
        });

    }
}

export default geolocation;
