const accordions = {
    init() {
        document.querySelectorAll('.accordion-item').forEach(function(accordionItem) {
            const toggle = accordionItem.querySelector('.title');

            if (accordionItem.classList.contains('open')) {
                const expanded = accordionItem.querySelector('.expanded');
                expanded.style.height = expanded.scrollHeight + "px";
            }

            toggle.onclick = function(){
                accordionItem.classList.toggle('open');

                const expanded = accordionItem.querySelector('.expanded');

                if (expanded.clientHeight) {
                    expanded.style.height = 0;
                } else {
                    expanded.style.height = expanded.scrollHeight + "px";
                }
            };
        });
    }
}

export default accordions