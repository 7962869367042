/**
 * Main Script File
 */

import lozadInitiator from "./global/lozadInitiator";
import animations from "./global/animations";
import megaMenu from "./global/megaMenu";
import mobileMenu from "./global/mobileMenu";
import travelTo from "./global/travelTo";
import modals from "./global/modals";
import selects from "./global/selects";
import accordions from "./global/accordions";
import headerSearch from "./global/headerSearch";
import sliders from "./global/sliders";
import dropdown from "./global/dropdown";
import geolocation from "./global/geolocation";

lozadInitiator.init();
animations.init();
megaMenu.init();
mobileMenu.init();
travelTo.init();
modals.init();
selects.init();
accordions.init();
headerSearch.init();
sliders.init();
dropdown.init();
geolocation.init();
