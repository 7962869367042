import lozad from 'lozad';

const lozadInitiator = {
    init() {
        var observer = lozad('.lozad');
        observer.observe();

        let bgImgs = document.querySelectorAll('.bg-img');

        processBackgrounds(bgImgs);

        if (typeof wp !== 'undefined' && typeof wp.blocks !== 'undefined') {
            console.log('log');
            // This is to initialise the bg imgs in the editor, otherwise they won't show
            window.setInterval(function () {
                let bgImgs = document.querySelectorAll('.bg-img');
                processBackgrounds(bgImgs, false);
            }, 500);
        }

        function processBackgrounds(bgImgs, lozad = true)
        {
            bgImgs.forEach(function(bgImg) {
                const picture = bgImg.querySelector('picture');
                let img_src = null;

                if (picture != null && picture.length < 1 && window.innerWidth < 400) {
                    img_src = picture.querySelector('source').getAttribute('srcset');
                }

                if (!img_src || img_src.length === 0) {
                    const img = bgImg.querySelector('img');

                    if (img != null && img.toString().length > 1) {
                        img_src = img.getAttribute('data-src');
                    }

                    if (img_src === null && img !== null) {
                        img_src = img.src;
                    }
                }

                if (img_src) {
                    if (bgImg.classList.contains('lozad') && lozad) {
                        bgImg.setAttribute('data-background-image', img_src);
                    } else {
                        bgImg.style.backgroundImage = 'url(' + img_src + ')';
                    }
                }
            });
        }
    }
}

export default lozadInitiator