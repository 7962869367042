import { throttle } from '../utils';

const mobileMenu = {
    init() {
        const header = document.querySelector('header');
        const mmToggle = document.querySelector('header .mm-toggle');
        const mobileMenu = document.querySelector('#mobile-menu');
        const openMenuClass = 'mobile-menu-open';

        if (mmToggle !== null) {
            mmToggle.addEventListener('click', () => {
                toggleNav(!mmToggle.classList.contains(openMenuClass), header, mmToggle, mobileMenu, openMenuClass);
            });
        }

        window.addEventListener(
            'resize',
            throttle(() => {
                toggleNav(false, header, mmToggle, mobileMenu, openMenuClass);
            }, 100),
            false,
        );

        document.querySelectorAll('.menu-item-has-children .menu-arrow').forEach(function(arrow) {
            arrow.onclick = function(){
                arrow.parentNode.classList.toggle('open');
                arrow.classList.toggle('open');
            };
        });
    }
}

export default mobileMenu

const toggleNav = (open, header, toggle, menu, openClass) => {
    const body = document.body;

    if (open) {
        const scrollY = window.scrollY;

        body.style.position = 'fixed';
        body.style.top = '-' + scrollY + 'px';
        body.classList.add(openClass);
        header.classList.add(openClass);
        menu.classList.add(openClass);
        toggle.classList.add(openClass);
    } else {
        const scrollY = body.style.top;

        body.style.position = '';
        body.style.top = '';
        window.scrollTo(0, parseInt(scrollY || '0') * -1);
        body.classList.remove(openClass);
        header.classList.remove(openClass);
        menu.classList.remove(openClass);
        toggle.classList.remove(openClass);
    }

}