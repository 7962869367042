import Choices from "choices.js";

const selects = {
    init() {
        const selects = document.querySelectorAll('.select-filter');

        selects.forEach(function(select) {
            //copy full select node with values
            let choicesTagsBefore = select.cloneNode(true);
            //convert to array
            choicesTagsBefore = Array.from(choicesTagsBefore.options);

            const choicesTagsObj = new Choices(select, {
                shouldSort: false,
                itemSelectText: ''
            });

            //fill again after form reset (Choices.js bug workaround)
            // https://github.com/Choices-js/Choices/issues/1023
            const filtersForm = document.getElementById('filters-form');
            if (filtersForm !== null) {
                filtersForm.addEventListener('reset', (ev) => {
                    choicesTagsObj.setChoices(async () => {
                        return choicesTagsBefore.map(({ value, label }) => ({
                            value,
                            label,
                            selected: false,
                            disabled: !value,
                        }));
                    });
                })
            }
        });
    }
}

export default selects