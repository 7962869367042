const modals = {
    init() {
        document.querySelectorAll('a[href*="#subscribe"]').forEach((subscribeAnchor) => {
            subscribeAnchor.onclick = (e) => {
                e.preventDefault();
                document.getElementById('subscribe').classList.add('active');
            };
        });

        document.querySelectorAll('a[href*="#contact-person"]').forEach((subscribeAnchor) => {
            subscribeAnchor.onclick = (e) => {
                e.preventDefault();
                const modal = document.getElementById('contact-person');

                const card = subscribeAnchor.parentNode.parentNode;

                if (card.classList.contains('person-card')) {
                    const name = card.querySelector('h3').innerText;
                    const profileImage = card.querySelector('.bg-img.normal').style.backgroundImage;

                    modal.querySelector('h2 span').innerText = name;
                    modal.querySelector('input[name=website_email___person_s_name]').value = name;
                    modal.querySelector('.profile-image').style.backgroundImage = profileImage;
                } else {
                    const name = document.querySelector('h1').innerText;
                    const profileImage = document.querySelector('.page-banner--people').style.backgroundImage;

                    modal.querySelector('h2 span').innerText = name;
                    modal.querySelector('input[name=website_email___person_s_name]').value = name;
                    modal.querySelector('.profile-image').style.backgroundImage = profileImage;
                }

                modal.classList.add('active');
            };
        });

        const needHelpModal = document.getElementById('need-help');

        if (needHelpModal) {
            const question1 = needHelpModal.querySelector('#question-1');
            const question1Buttons = question1.querySelectorAll('button');
            const question2 = needHelpModal.querySelector('#question-2');
            const question2Buttons = question2.querySelectorAll('button');
            const question3 = needHelpModal.querySelector('#question-3');
            const question4 = needHelpModal.querySelector('#question-4');
            const needHelpClose = needHelpModal.getElementsByClassName('close')[0];
            const needHelpReset = needHelpModal.getElementsByClassName('js-reset')[0];

            document.querySelectorAll('a[href*="#need-help"]').forEach((subscribeAnchor) => {
                subscribeAnchor.onclick = (e) => {
                    e.preventDefault();
                    needHelpModal.classList.add('active');
                };
            });

            [needHelpReset, needHelpClose].forEach((item) => {
                item.addEventListener('click', () => {
                    resetQuestions({ initial: question1, questions: [question2, question3, question4], buttons: [question3, question4], asset_owners: [question2], reset: needHelpReset});
                    buttonAbility(question2Buttons, false);
                });
            })
            
            question1Buttons.forEach((question1Button) => {
                question1Button.addEventListener('click', () => {
                    const expertiseID = question1Button.getAttribute('data-expertise');

                    question1.classList.remove('active');
                    needHelpReset.classList.add('active');
                    question2.classList.add('active');
                    question2.querySelector('.asset-owners[data-expertise="' + expertiseID + '"]').classList.add('active');
                });
            });

            question2Buttons.forEach((question2Button) => {
                question2Button.addEventListener('click', () => {
                    const expertiseID = question2Button.getAttribute('data-expertise');

                    buttonAbility(question2Buttons, true);

                    fetch(cdScriptsObj.ajax_url, {
                        method: 'POST',
                        credentials: 'same-origin',
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                            'Cache-Control': 'no-cache',
                        },
                        body: new URLSearchParams({
                            action: 'needHelpQuery',
                            expertiseID: JSON.stringify(expertiseID),
                        }),
                    }).then((response) => {
                        response.json().then((results) => {
                            question2.classList.remove('active');
                            question3.classList.add('active');

                            question3.querySelector('.buttons').innerHTML = results.buttons;

                            questionButtons(needHelpModal, '#question-3', question3, question4);
                        });
                    }).catch((err) => console.error(err));
                });
            });
        }

        document.querySelectorAll('.modal .close').forEach((modalClose) => {
            modalClose.onclick = (e) => {
                e.preventDefault();
                modalClose.parentNode.parentNode.classList.remove('active');
            };
        });
    }
}

export default modals

const buttonAbility = (buttons, disabled) => {
    buttons?.forEach((button) => {
        button.disabled = disabled;
    });
}

const resetQuestions = ({ initial, questions, buttons, asset_owners, reset }) => {
    initial?.classList.add('active');
    reset.classList.remove('active');

    questions?.forEach((question) => {
        question.classList.remove('active');
    });

    buttons?.forEach((button) => {
        button.getElementsByClassName('buttons')[0].innerHTML = '';
    });

    asset_owners?.forEach((owner) => {
        Array.from(owner.getElementsByClassName('asset-owners')).forEach((el) => {
            el.classList.remove('active');
        });
    });
}

const questionButtons = (modal, question, currentQuestion, nextQuestion) => {
    const questionButtons = modal?.querySelectorAll(`${question} button`);

    questionButtons?.forEach((questionButton) => {
        questionButton.onclick = () => {
            const expertiseID = questionButton.getAttribute('data-expertise');
            const serviceCatID = questionButton.getAttribute('data-service-cat');

            buttonAbility(questionButtons, true);

            fetch(cdScriptsObj.ajax_url, {
                method: 'POST',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Cache-Control': 'no-cache',
                },
                body: new URLSearchParams({
                    action: 'needHelpQuery',
                    expertiseID: JSON.stringify(expertiseID),
                    serviceCatID: JSON.stringify(serviceCatID),
                }),
            }).then((response) => {
                response.json().then((results) => {
                    currentQuestion.classList.remove('active');
                    nextQuestion.classList.add('active');

                    nextQuestion.querySelector('.buttons').innerHTML = results.buttons;
                });
            }).catch((err) => console.error(err));
        };
    });
}
