const headerSearch = {
    init() {
        const search = document.querySelector('.search-form .search');

        if (search !== null) {
            search.onclick = function(e) {
                e.preventDefault();
                const form = document.querySelector('.search-form form');

                if (form.classList.contains('active')) {
                    form.classList.remove('active');
                } else {
                    form.classList.add('active');
                }
            };
        }
    }
}

export default headerSearch