export function offset (elt) {
    var rect = elt.getBoundingClientRect();

    return {
        top: rect.top + window.scrollY,
        left: rect.left + window.scrollX,
    }
}

export function scrollDownWithUser(el, topMargin = 140, maxScroll = 0, height = false) {
    if(! el) {
        return;
    }

    let originalHeight = el.offsetHeight;

    function setScrollTop() {
        let originalY = offset(el).top;

        var scrollTop = window.scrollY,
            top = scrollTop < originalY
                ? 0
                : scrollTop - originalY + topMargin;

        if (maxScroll) {
            top = maxScroll < top ? maxScroll : top;
        }

        if (top > 0) {
            el.classList.add('active');
        } else {
            el.classList.remove('active');
        }

        if (height) {
            el.style.height = (top + originalHeight) + 'px';
        } else {
            el.style.top = top + 'px';
        }
    }

    setScrollTop();

    window.addEventListener('scroll', function() {
        setScrollTop();
    });
}

export function hasClass(el, cls) {
    if (el.className.match('(?:^|\\s)'+cls+'(?!\\S)')) { return true; }
}

export function addClass(el, cls) {
    if (!el.className.match('(?:^|\\s)'+cls+'(?!\\S)')) { el.className += ' '+cls; }
}

export function throttle(fn, ms) { // Ryan Taylor comment - https://remysharp.com/2010/07/21/throttling-function-calls
    var time, last = 0;
    return function() {
        var a = arguments, t = this, now = +(new Date), exe = function() { last = now; fn.apply(t, a); };
        clearTimeout(time);
        (now >= last + ms) ? exe() : time = setTimeout(exe, ms);
    }
}