import {addClass, hasClass} from "./helpers";

const travelTo = {
    init() {
        const anchorsWrappers = document.querySelectorAll('.travel-to-wrapper');

        if (anchorsWrappers) {
            anchorsWrappers.forEach(function (anchorsWrapper) {
                const autoUpdate = anchorsWrapper.getAttribute('data-auto-update');
                const anchors = document.querySelectorAll('.travel-to');
                let blocks = [];

                anchors.forEach(function(anchor) {
                    const id = anchor.getAttribute('data-id');
                    const block = document.getElementById(id);
                    blocks.push(block);

                    anchor.addEventListener('click', (e) => {
                        e.preventDefault();
                        let position = block.getBoundingClientRect();
                        let blockTop = position.top + window.scrollY;

                        if (hasClass(anchor, 'nav-tab')) {
                            const tabSectionHeight = anchor.parentNode.parentNode.offsetHeight;
                            blockTop = blockTop - tabSectionHeight;
                        }

                        window.scrollTo({
                            top: blockTop,
                            left: position.left,
                            behavior: 'smooth'
                        });
                    });
                });

                function isElementInViewport (el) {
                    var rect = el.getBoundingClientRect();

                    return (rect.bottom >= 0 && rect.right >= 0 && rect.top <= (window.innerHeight || document.documentElement.clientHeight) && rect.left <= (window.innerWidth || document.documentElement.clientWidth));
                }

                function setAnchorAsActive (block) {
                    const id = block.id;
                    const activeAnchors = document.querySelectorAll('[data-id="'+id+'"]');
                    activeAnchors.forEach(function (activeAnchor) {
                        if (! hasClass(activeAnchor, 'active')) {
                            activeAnchor.parentNode.querySelectorAll('a').forEach(function (anchor) {
                                anchor.classList.remove('active');
                            });

                            addClass(activeAnchor, 'active');
                        }
                    });
                }

                if (autoUpdate === 'true') {
                    window.addEventListener('scroll', function() {
                        let visibleBlocks = [];

                        blocks.forEach(function(block) {
                            if (isElementInViewport(block)) {
                                const top = block.getBoundingClientRect().top;
                                visibleBlocks.push({
                                    block: block,
                                    top: top,
                                });
                            }
                        });

                        if (visibleBlocks.length !== 0) {
                            if (visibleBlocks.length > 1) {
                                if (Math.abs(visibleBlocks[0].top) > visibleBlocks[1].top) {
                                    setAnchorAsActive(visibleBlocks[1].block)
                                } else {
                                    setAnchorAsActive(visibleBlocks[0].block)
                                }
                            } else {
                                setAnchorAsActive(visibleBlocks[0].block)
                            }
                        }
                    }, false);
                }
            });
        }
    }
}

export default travelTo