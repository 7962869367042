/**
 * Throttle to prevent function overload
 * @param {fn} fn - Function
 * @param {ms} ms - Milliseconds
 */
const throttle = (fn, ms) => {
    let time;
    let last = 0;
    return () => {
        const a = arguments;
        const t = this;
        const now = +new Date();
        const exe = () => {
            last = now;
            fn.apply(t, a);
        };
        clearTimeout(time);
        now >= last + ms ? exe() : (time = setTimeout(exe, ms));
    };
};

export {
    throttle,
};
