import Swiper, { Navigation, Pagination } from 'swiper';

Swiper.use([Navigation, Pagination]);

const options = {
    whoWeHelpClass: '.swiper.who-we-help-slider',
    imageClass: '.swiper.image-carousel-wrapper',
    testimonialClass: '.swiper.testimonial-slider-wrapper',
    twoColumnClass: '.swiper.two-column-slider-wrapper',
    logosClass: '.swiper.logos-slider',
    insightsClass: '.swiper.insights-slider',
}

const sliders = {
    init() {
        const whoWeHelpSlider = document.querySelectorAll(options.whoWeHelpClass);
        const imageSlider = document.querySelectorAll(options.imageClass);
        const testimonialSlider = document.querySelectorAll(options.testimonialClass);
        const twoColumnSlider = document.querySelectorAll(options.twoColumnClass);
        const logosSlider = document.querySelectorAll(options.logosClass);
        const insightsSlider = document.querySelectorAll(options.insightsClass);

        whoWeHelpSlider.forEach((item, index) => {
            const instanceClass = `swiper-${index}`;

            item.classList.add(instanceClass);
            swiperInit(`${options.whoWeHelpClass}.${instanceClass}`, {
                loop: true,
                pagination: pagination(),
                navigation: navigation(),
            });
        });

        imageSlider.forEach((item, index) => {
            const instanceClass = `swiper-${index}`;

            item.classList.add(instanceClass);
            swiperInit(`${options.imageClass}.${instanceClass}`, {
                loop: true,
                autoHeight: true,
                navigation: navigation(),
            });
        });

        testimonialSlider.forEach((item, index) => {
            const instanceClass = `swiper-${index}`;

            item.classList.add(instanceClass);
            swiperInit(`${options.testimonialClass}.${instanceClass}`, {
                loop: true,
                navigation: navigation(),
            });
        });

        twoColumnSlider.forEach((item, index) => {
            const instanceClass = `swiper-${index}`;

            item.classList.add(instanceClass);
            swiperInit(`${options.twoColumnClass}.${instanceClass}`, {
                loop: true,
                navigation: navigation(),
            });
        });

        logosSlider.forEach((item, index) => {
            const instanceClass = `swiper-${index}`;

            item.classList.add(instanceClass);
            swiperInit(`${options.logosClass}.${instanceClass}`, {
                slidesPerView: 'auto',
                pagination: pagination(),
            });
        });

        insightsSlider.forEach((item, index) => {
            const instanceClass = `swiper-${index}`;

            item.classList.add(instanceClass);
            swiperInit(`${options.insightsClass}.${instanceClass}`, {
                loop: true,
                slidesPerView: 'auto',
                spaceBetween: 30,
                navigation: navigation(),
            });
        });
    }
}

export default sliders

const swiperInit = (slider, args) => {
    const eventArgs = {
        on: {
            afterInit: (carousel) => {
                slideCount(carousel);
            }
        }
    }

    if ('navigation' in args) {
        args['navigation']['nextEl'] = `${slider} ${args['navigation']['nextEl']}`;
        args['navigation']['prevEl'] = `${slider} ${args['navigation']['prevEl']}`;
    }

    if ('pagination' in args) {
        args['pagination']['el'] = `${slider} ${args['pagination']['el']}`;
    }

    new Swiper(slider, { ...args, ...eventArgs });
}

const navigation = () => {
    return {
        nextEl: '.next',
        prevEl: '.prev',
    }
}

const pagination = () => {
    return {
        el: '.pagination',
        clickable: true
    }
}

const slideCount = (carousel) => {
    if (carousel.slides.length <= 1) {
        carousel.el.classList.add('hide-controls');
    }
}