import {addClass, hasClass, throttle} from "./helpers";

const animations = {
    init() {
        function animateValue(obj, start, end, duration) {
            let startTimestamp = null;
            const step = (timestamp) => {
                if (!startTimestamp) startTimestamp = timestamp;
                const progress = Math.min((timestamp - startTimestamp) / duration, 1);
                obj.innerHTML = Math.floor(progress * (end - start) + start);
                if (progress < 1) {
                    window.requestAnimationFrame(step);
                }
            };
            window.requestAnimationFrame(step);
        }

        function elementFromTop(elem, classToAdd, distanceFromTop, unit, delay) {
            var winY = window.innerHeight || document.documentElement.clientHeight,
                elemLength = elem.length, distTop, distPercent, distPixels, distUnit, i;

            for (let i = 0; i < elemLength; ++i) {
                distTop = elem[i].getBoundingClientRect().top;
                distPercent = Math.round((distTop / winY) * 100);
                distPixels = Math.round(distTop);
                distUnit = unit == 'percent' ? distPercent : distPixels;
                if (distUnit <= distanceFromTop) {
                    if (classToAdd === 'count-up') {
                        animateValue(elem[i], 0, elem[i].innerText, 1000)
                    } else {
                        if (!hasClass(elem[i], classToAdd)) {
                            if (delay) {
                                setTimeout(function() {
                                    addClass(elem[i], classToAdd);
                                }, delay * i);
                            } else {
                                addClass(elem[i], classToAdd);
                            }
                        }
                    }
                }
            }
        }

        const animationClasses = document.querySelectorAll('.fade-in, .slide-in-left, .slide-in-right');
        elementFromTop(animationClasses, 'active',  90, 'percent'); // as top of element hits top of viewport

        const countUp = document.querySelectorAll('.count-up');
        elementFromTop(countUp, 'count-up',  90, 'percent'); // as top of element hits top of viewport

        window.addEventListener('scroll', throttle(function() {
            elementFromTop(animationClasses, 'active',  90, 'percent'); // as top of element hits top of viewport
        }, 100), false);

        const rowLoad = document.querySelectorAll('.row-load');

        function sliceIntoChunks(arr, chunkSize) {
            const res = [];
            for (let i = 0; i < arr.length; i += chunkSize) {
                const chunk = arr.slice(i, i + chunkSize);
                res.push(chunk);
            }
            return res;
        }

        for (let i = 0; i < rowLoad.length; i++) {
            // calc computed style
            const gridComputedStyle = window.getComputedStyle(rowLoad[i]);

            // items per row
            const perChunk = gridComputedStyle.getPropertyValue('grid-template-columns').split(' ').length;

            // Convert htmlcollection to array
            var inputArray = [].slice.call(rowLoad[i].children);

            // Slice cols into rendered rows
            const rows = sliceIntoChunks(inputArray, perChunk);

            // loop the rows
            for (let r = 0; r < rows.length; r++) {
                elementFromTop(rows[r], 'active', 90, 'percent', 200);

                window.addEventListener('scroll', throttle(function() {
                    elementFromTop(rows[r], 'active',  90, 'percent', 200);
                }, 100), false);
            }
        }
    }
}

export default animations