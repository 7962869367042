const megaMenu = {
    init() {
        const page = document.querySelector('#page');
        const mmopens = document.querySelectorAll('.mm-open');
        const megamenus = document.querySelectorAll('.mega-menu');
        const openMenuClass = 'open';

        mmopens.forEach(function(mmopen) {
            const targetMM = document.querySelector('#' + mmopen.getAttribute('data-mm'));
            targetMM.querySelector('.inner').style.left = mmopen.getBoundingClientRect().left + 'px';

            window.addEventListener('resize', function(event) {
                targetMM.querySelector('.inner').style.left = mmopen.getBoundingClientRect().left + 'px';
            }, true);

            mmopen.addEventListener('click', (e) => {
                e.preventDefault();
                
                targetMM.querySelector('.inner').style.left = mmopen.getBoundingClientRect().left + 'px';

                if (mmopen.classList.contains(openMenuClass)) {
                    mmopen.classList.remove(openMenuClass);
                    targetMM.classList.remove(openMenuClass);
                    page.classList.remove('mega-menu-open');
                } else {
                    mmopens.forEach(function (mmopenClass) {
                        mmopenClass.classList.remove(openMenuClass);
                    });
                    megamenus.forEach(function (megamenu) {
                       megamenu.classList.remove(openMenuClass);
                    });
                    mmopen.classList.add(openMenuClass);
                    targetMM.classList.add(openMenuClass);
                    page.classList.add('mega-menu-open');
                }
            });
        });

        megamenus.forEach(function(megamenu) {
            const MMClose = megamenu.querySelector('.close');

            MMClose.addEventListener('click', () => {
                mmopens.forEach(function(mmopen) {
                    mmopen.classList.remove(openMenuClass);
                });
                page.classList.remove('mega-menu-open');
                megamenu.classList.remove(openMenuClass);
            });
        });
    }
}

export default megaMenu