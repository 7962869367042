const dropdown = {
    init() {

        const langSelector = document.getElementsByClassName('js-language-selector');
        const langGlobal = document.querySelector('.language-global');
        const langMenuSelect = document.querySelector('.lang-menu-select');

        if (langSelector.length > 0) {
            Array.from(langSelector).forEach((el) => {
                const root = document.documentElement;
                const elWidth = el.offsetWidth;

                root.style.setProperty('--language-drop-width', `${elWidth}px`);
            });
        }

        langGlobal.addEventListener('click', function(event) {
            event.stopPropagation();
            langMenuSelect.style.display = langMenuSelect.style.display === 'block' ? 'none' : 'block';
        });

        document.addEventListener('click', function() {
            langMenuSelect.style.display = 'none';
        });

        langMenuSelect.addEventListener('click', function(event) {
            event.stopPropagation();
        });
    }
}

export default dropdown